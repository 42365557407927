import React from "react";
import image from "../assets/PUMA_Bankrupcty_Predictions.png";

const BankruptcyAnalysisContent = () => {
	return (
		<div>
			<div className="b-divider"></div>

			<div>
				<h1>Bankruptcy Analysis at the Community Level</h1>
				<hr />
				<h4>
					A Novel Analysis of Community Bankruptcy and American
					Community Survey Data
				</h4>
				Research conducted by Joel Breit in 2020 under the mentorship of
				Carlos Siordia, Ph.D. funded by the Health Resources and
				Services Administration and presented at the
				<a
					href="https://wvrha.org/wvrha-conference/"
					target="_blank"
					rel="noopener noreferrer"
				>
					{" "}
					2020 West Virginia Rural Health Association Conference
				</a>
				.<h4>Abstract</h4>
				<p>
					{" "}
					The goal of this analysis was to find community-level
					correlation between bankruptcy rates and common factors in
					individual bankruptcies so that predictions can be made
					about future trends in individual bankruptcy in American
					communities. In this research, data for common causes of
					personal bankruptcy were collected for 2,378 sub-state
					regions in the US from the American Community Survey (U.S.
					Census Bureau, 2013-2018) and matched to bankruptcy rates
					from the Administrative Office of the U.S. Courts
					(Administrative Office of the U.S. Courts, 2013-2018). It
					was found that communities with larger Black, divorced,
					middle-aged, middle-educated, non-immigrant, uninsured,
					disabled, employed, and veteran populations, all have higher
					bankruptcy rates in the US as expected based on previous
					research on the topic (Fisher, 2017; Himmelstein, Thorne,
					Warren, & Woolhandler, 2007). A predictive multiple
					regression model was developed using this data from
					2013-2018 and was able to account for a modest amount of the
					variation between areas (R2 = 0.14) as well as produce a
					highly accurate estimate for novel data within that
					timeframe.
				</p>
				<h4>Introduction</h4>
				<p>
					Every year, over 700,000 Americans file for personal
					bankruptcy in the United States (Administrative Office of
					the U.S. Courts, 2013-2018). This means that these Americans
					are unable to pay off their debts whether that be from
					loans, medical bills, credit cards, or other expenses and
					had to go to court to eliminate or renegotiate it. In 2015,
					approximately $47 billion of debt was forgiven not including
					the interest that that debt may have accrued (Fisher, 2017).
				</p>
				<p>
					Bankruptcy prediction is common for business bankruptcies
					(Dorsey, Edmister, & Johnson, 1995), but virtually
					unexplored for personal bankruptcies although it has been
					explored using credit card usage data (Xiong, Wang, Mayers,
					& Monga, 2013). This is likely because data on companies
					that file for bankruptcies is much more publicly available.
					Although research has been done on what causes personal
					bankruptcy, little has been done on how to reduce or
					mitigate it (Fisher, 2017; Xiong, et al., 2013; Dorsey,
					Edmister, & Johnson, 1995). For this research, I collected
					demographic data, population estimates, and bankruptcy
					numbers for each community for each year in order to create
					a large dataset of community characteristics and bankruptcy
					rates. The demographics that were collected were based on
					other research on the causes of individual bankruptcy. The
					causes of personal bankruptcy at the community level has, to
					my knowledge, been unexplored in academic research. For the
					purpose of this analysis, I theorized that, much like how
					health outcomes (e.g. life expectancy) can vary both by
					individual characteristics and by community characteristics,
					the likelihood of personal bankruptcy could be impacted by
					community-level factors such as economic stability and
					social safety net funding (Graham, 2016).
				</p>
				<h4>Methodology</h4>
				<p>
					In order to associate bankruptcy rates with predictive
					variables, I mapped together data from the American
					Community Survey (ACS), US Census, and US Courts with the
					assistance of the Missouri Census Data Center’s (MCDC) MABLE
					Geocorr 2014 tool (Geocorr, 2014). The American Community
					Survey provides demographic data for millions of households
					each year (U.S. Census Bureau, 2015-2019). Each of these
					entries specifies the geographic region from which it was
					collected. These geographic regions are ACS specific
					constructs called Public Use Microdata Areas (PUMAs). PUMAs
					do not cross state lines, and they contain at least 100,000
					Americans. PUMAs sometimes consist of several counties (e.g.
					in rural areas) or a single county can contain several
					PUMAs. Each of these PUMAs has many person entries with
					information about race, age, education, etc. which can be
					combined to calculate community characteristics (e.g. median
					age) (US Census Bureau, 2018). Personal bankruptcy is not a
					variable accounted for in the American Community Survey but
					is provided on a county level by the Administrative Office
					of the U.S. Courts. PUMAs were mapped to counties using the
					MCDC MABLE Geocorr 2014 tool (Geocorr, 2014). Bankruptcy
					rates and community variables were collected for the years
					of 2013 to 2018 with changes in county population accounted
					for using US Census county population estimates by year (US
					Census Bureau, 2020).
				</p>
				<p>
					The variables used for this model were (in order of
					predictive strength) race, divorce, age, immigrant status,
					health insurance, education, disability status,
					unemployment, and veteran status. Health insurance is a
					variable that has been referenced repeatedly in academic
					studies as a factor in personal bankruptcies (Himmelstein,
					2007), and every other variable has been identified at least
					in Jonathan Fisher’s article, “Who Files for Personal
					Bankruptcy in the United States?” (Fisher, 2017). In my
					model, these variables were all defined in a way to make a
					linear regression model more likely to fit. Race was coded
					as the percent of a PUMA that was Black, age was coded as
					the percent of a PUMA that was between the ages of 35 and
					54, and education was coded as the percent of a PUMA that
					had either just graduated high school or had just some
					college education.
				</p>
				<p>
					The predictive variables that I used all had the same
					direction of correlation as for individual level personal
					bankruptcy in Jonathon Fisher’s research on the topic
					although the degree of correlation varied from the results
					that he found (Fisher, 2017).
				</p>
				<table className="table table-striped">
					<thead className="table-light">
						<tr>
							<th></th>
							<th>Race</th>
							<th>Divorce</th>
							<th>Middle-Age</th>
							<th>Immigrant</th>
							<th>Insurance</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Min</td>
							<td>0%</td>
							<td>3%</td>
							<td>5%</td>
							<td>0%</td>
							<td>45%</td>
						</tr>
						<tr>
							<td>Median</td>
							<td>7%</td>
							<td>12%</td>
							<td>16%</td>
							<td>7%</td>
							<td>92%</td>
						</tr>
						<tr>
							<td>Average</td>
							<td>13%</td>
							<td>12%</td>
							<td>17%</td>
							<td>11%</td>
							<td>90%</td>
						</tr>
						<tr>
							<td>Max</td>
							<td>97%</td>
							<td>23%</td>
							<td>34%</td>
							<td>76%</td>
							<td>100%</td>
						</tr>
					</tbody>
				</table>
				<table className="table table-striped">
					<thead className="table-light">
						<tr>
							<th></th>
							<th>Disability</th>
							<th>Middle-Education</th>
							<th>Unemployment</th>
							<th>Veteran</th>
							<th>Bankruptcies / 100,000</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Min</td>
							<td>5%</td>
							<td>12%</td>
							<td>37%</td>
							<td>0%</td>
							<td>0</td>
						</tr>
						<tr>
							<td>Median</td>
							<td>19%</td>
							<td>43%</td>
							<td>65%</td>
							<td>7%</td>
							<td>223</td>
						</tr>
						<tr>
							<td>Average</td>
							<td>19%</td>
							<td>42%</td>
							<td>65%</td>
							<td>7%</td>
							<td>260</td>
						</tr>
						<tr>
							<td>Max</td>
							<td>43%</td>
							<td>64%</td>
							<td>86%</td>
							<td>50%</td>
							<td>1,425</td>
						</tr>
					</tbody>
				</table>
				<p>
					In total, this gave me a sample of 14,103 1-year specific
					communities with their associated bankruptcy rates and
					demographic profiles which I used to create a multiple
					regression model.
				</p>
				<h4>Findings</h4>
				<p>
					The multiple regression model based on the above predictive
					variables was able to predict to within a standard deviation
					of the true bankruptcy rate of each PUMA 79% of the time (vs
					68% for the mean), and the model’s R2 value was 0.14.
				</p>
				<img
					src={image}
					className="img-fluid rounded"
					alt="PUMA Bankruptcy Predictions"
				/>
				<p>
					Because this model was created with predicting future data
					in mind, it was tested with an 80-20 training-testing data
					split. This means a multiple regression model was created
					based on a randomly selected portion of the entries equal to
					80% of the data (the training set), and that model was
					compared to the remaining 20% of the data (the testing set)
					to see how well it could predict results for data it had
					never “seen” before. In this scenario, the R2 value remained
					at 0.14 and the total number of bankruptcies was predicted
					to within 00.6% of the actual value.
				</p>
				<p>
					The figure below shows the R2 value and correlation
					coefficient for each variable. It shows that communities
					with larger Black, divorced, middle-aged, middle-educated,
					disabled, and veteran populations, had higher bankruptcy
					rates, and communities with larger immigrant, insured, and
					unemployed populations had lower bankruptcy rates.
				</p>
				<table className="table table-striped">
					<thead>
						<tr>
							<th></th>
							<th>Race</th>
							<th>Divorce</th>
							<th>Middle-Age</th>
							<th>Immigrant</th>
							<th>Insurance</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>R2</td>
							<td>0.10</td>
							<td>0.03</td>
							<td>0.02</td>
							<td>0.02</td>
							<td>0.01</td>
						</tr>
						<tr>
							<td>Correlation</td>
							<td>0.31</td>
							<td>0.17</td>
							<td>0.15</td>
							<td>-0.13</td>
							<td>-0.12</td>
						</tr>
					</tbody>
				</table>
				<table className="table table-striped">
					<thead>
						<tr>
							<th></th>
							<th>Disability</th>
							<th>Middle Education</th>
							<th>Unemployment</th>
							<th>Veteran</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>R2</td>
							<td>0.01</td>
							<td>0.01</td>
							<td>0.00</td>
							<td>0.00</td>
						</tr>
						<tr>
							<td>Correlation</td>
							<td>0.10</td>
							<td>0.09</td>
							<td>-0.05</td>
							<td>0.04</td>
						</tr>
					</tbody>
				</table>
				<p>
					Finally, to demonstrate the kind of predictions this model
					can be used for, the original model was used to predict
					bankruptcy rates if all Americans had health insurance. This
					modification does not imply a hypothetical situation where
					health problems or bills would be removed from the factors
					that cause personal bankruptcy, but rather that all
					Americans had assistance from health insurance equal to that
					of the average American with insurance from 2013-2018. The
					model predicts that in this scenario, bankruptcy would drop
					by 4% or roughly 33,000 filings per year.
				</p>
				<h4>Conclusion</h4>
				<p>
					Using this model as a predictive tool, we may be able to
					better predict changes in personal bankruptcy filings and be
					able to be informed as to how community or policy changes
					could affect those numbers and our communities. The model
					used in this research is available to the public for future
					research along with instructions for replication (Steadman,
					2020). As discussed below, there is much more that can be
					done with this data, and more analysis needs to be devoted
					to these results. While the model created by this research
					is not able to predict the chance of bankruptcy for an
					individual nor able to account for a majority of variation
					between communities, it can give modest insight into the
					differences among regions and make decent predictions about
					large scale trends in personal bankruptcies based on
					demographic variables which are more commonly made
					available.
				</p>
				<h4>Discussion</h4>
				<p>
					The most highly predictive variable in this model by a
					considerable margin was race. Specifically, it was the
					percent of a community that was Black according to ACS data.
					This value was ranged from 0% in several communities to 97%
					(south Chicago). As found before by Jonathon Fisher (2017),
					Black Americans are more likely to file for bankruptcy, but
					other minorities are not. This factor had the largest
					variation of those analyzed in this research which could
					have factored into its significance in predicting variation
					in bankruptcy rates. Race still plays a much larger role at
					the community level than in studies on individuals, and more
					analysis should be devoted to the topic.
				</p>
				<p>
					Another topic that has been looked into many times is the
					prevalence of medically related personal bankruptcies.
					Estimates of what proportion of personal bankruptcies are
					medical bankruptcies range from 5- to 60-% (Himmelstein et
					al., 2009; Dobkin, Finkelstein, Kluender, & Notowidigdo,
					2018). Himmelstein et al. found the 60% number in 2009 by
					interviewing a few thousand filers and counting any that had
					significant medical bills (Himmelstein, 2009), and the 5%
					number comes from a study done in California following a
					number of patients after discharge from hospitals (Dobkin,
					et al., 2018). The analysis I have done deals with a vastly
					larger sample size, and, while it does not provide much
					insight into the precise proportion of personal bankruptcies
					that are medically related, it does suggest that a lack of
					insurance altogether is contributing to a significant number
					of personal bankruptcies.
				</p>
				<h4>Limitations and Directions for Future Research</h4>
				<p>
					Data was difficult to retrieve for this research and the
					results should be understood within that context. As
					discussed above, the independent variables (demographics)
					and dependent variable (bankruptcies) had to be stitched
					together from different sources which used different
					geographical definitions. In order to calculate the
					bankruptcy rate of a PUMA that contains 3 counties, the
					bankruptcy rates of each county needed to be combined and
					weighted for population. However, some counties cross PUMA
					lines, so an assumption had to be made that bankruptcy rate
					was generally homogenous across those counties. This is not
					ideal, but it is the only way to associate the rich
					population data collected in the ACS with community-specific
					bankruptcy data.
				</p>
				<p>
					This data is also frequently changing and always a year or
					two behind. When this research was done (early 2020), only
					data from the 2018 ACS was available, and data for county
					level bankruptcy was only available back to 2013.
					Furthermore, PUMA definitions change every decade when new
					census data is available for drawing new geographical lines.
					In addition, policy changes such as BAPCPA, enacted in 2005,
					can make the predictions made using this model highly
					tenuous (Fisher, 2017).
				</p>
				<p>
					Finally, ACS PUMAs are a bit too broad of communities for
					this kind of predictive modeling. Populations of 100,000+
					are not so much communities as they are bloated and
					arbitrary cohorts. In the future, effort should be expended
					to link up 5-year ACS data which can consist of smaller
					numbers of people with county bankruptcy rates (ACS General
					Handbook, 2018).
				</p>
			</div>
		</div>
	);
};

export default BankruptcyAnalysisContent;
