// Bootstrap
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Animate on Scroll
import 'aos/dist/aos.css';
import AOS from 'aos';

// Custom CSS
import "./assets/sass/color-theme.scss";
import "./index.css";

// Dependencies
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom/client";

// Pages
import AboutPage from "./pages/AboutPage";
import BankruptcyAnalysisPage from "./pages/BankruptcyAnalysisPage";
import EvolutionarySimulationPage from "./pages/EvolutionarySimulationPage";
import HomePage from "./pages/HomePage";
import JAMIPage from "./pages/JAMIPage";
import JuxComposePage from "./pages/JuxComposePage";
import MusicRendererPage from "./pages/MusicRendererPage";
import OrchestrAIPage from "./pages/OrchestrAIPage";
import ProjectsPage from "./pages/ProjectsPage";
import ResumePage from "./pages/ResumePage";

// Animation configuration
AOS.init({
	duration: 1000,
	easing: 'ease-in-out',
	once: true,
	mirror: false
});

const App = () => {
	return (
		<React.StrictMode>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/projects" element={<ProjectsPage />} />
					<Route path="/about" element={<AboutPage />} />
					<Route path="/resume" element={<ResumePage />} />
					<Route
						path="/bankruptcy_analysis"
						element={<BankruptcyAnalysisPage />}
					/>
					<Route
						path="/evolutionary_simulation"
						element={<EvolutionarySimulationPage />}
					/>
					<Route path="/jami" element={<JAMIPage />} />
					<Route
						path="/music_renderer"
						element={<MusicRendererPage />}
					/>
					<Route path="/juxcompose" element={<JuxComposePage />} />
					<Route path="/orchestrAI" element={<OrchestrAIPage />} />
				</Routes>
			</BrowserRouter>
		</React.StrictMode>
	);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
