import React from "react";
import BankruptcyAnalysisContent from "../components/BankruptcyAnalysisContent";
import Page from "../components/Page";

const BankruptcyAnalysisPage = () => {
	return (
		<Page>
			<BankruptcyAnalysisContent />
		</Page>
	);
};

export default BankruptcyAnalysisPage;
