import React from "react";
import AboutContent from "../components/AboutContent";
import Page from "../components/Page";

const AboutPage = () => {
	return (
		<Page>
			<AboutContent />
		</Page>
	);
};

export default AboutPage;
