import React from "react";
import JAMIContent from "../components/JAMIContent";
import Page from "../components/Page";

const JAMIPage = () => {
	return (
		<Page>
			<JAMIContent />
		</Page>
	);
};

export default JAMIPage;
