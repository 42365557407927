import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container } from "reactstrap";

const Page = ({ children }) => {
	return (
		<div className="page-wrapper">
			<div className="page-content">
				<Header />
				{/* TODO container class doesn't do anything? */}
				<main className="container">
					<Container className="col-12 col-lg-8">
						{children}
					</Container>
				</main>
				<Footer />
			</div>
		</div>
	);
};

export default Page;
