import {
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Navbar,
	NavbarBrand,
	NavbarToggler,
} from "reactstrap";
import React, { useState } from "react";

import { NavLink as RouterNavLink } from "react-router-dom";

const Header = (args) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	return (
		<div>
			<Navbar
				expand="md"
				// color="primary"
				// dark
				{...args}
				className="px-2"
			>
				<NavbarBrand tag={RouterNavLink} to="/">
					<div
						style={{
							fontWeight: "bold",
						}}
						className="d-flex align-items-center"
					>
						<div className="logo-initials me-2">JB</div>
						<span className="logo-text">Joel Breit</span>
					</div>
				</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="ms-auto" navbar>
						<NavItem>
							<NavLink tag={RouterNavLink} to="/">
								About Me
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RouterNavLink} to="/projects">
								Projects
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RouterNavLink} to="/about">
								About This Site
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RouterNavLink} to="/resume">
								Resume
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
};

export default Header;
