import React from "react";
import JuxComposeContent from "../components/JuxComposeContent";
import Page from "../components/Page";

const JuxComposePage = () => {
	return (
		<Page>
			<JuxComposeContent />
		</Page>
	);
};

export default JuxComposePage;
