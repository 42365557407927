import React from "react";
import Page from "../components/Page";
import ResumeContent from "../components/ResumeContent";

const ResumePage = () => {
	return (
		<Page>
			<ResumeContent />
		</Page>
	);
};

export default ResumePage;
