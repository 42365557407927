import React from "react";
import CCPBadge from "../assets/ccp-badge.png";
import CSABadge from "../assets/csa-badge.png";
import ScrumMasterBadge from "../assets/scrum-master-badge.png";

const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<footer className="bg-light text-center text-lg-start mt-5">
			<div className="container mt-4"></div>
			<div className="text-center p-3">
				<div className="row">
					<div className="col-lg-6 col-md-12 mb-4 mb-md-0"></div>
				</div>
				<h5 className="text-uppercase">Contact Info</h5>
				<ul className="list-unstyled mb-0">
					<li>joel [at] breitest [dot] com</li>
					<li>
						<a
							href="https://github.com/joelbreit"
							target="_blank"
							rel="noreferrer"
							className="text-dark"
						>
							GitHub Profile{" "}
							<i className="bi bi-github" aria-hidden="true"></i>
						</a>
					</li>
					<li>
						<a
							href="https://www.linkedin.com/in/joel-breit"
							target="_blank"
							rel="noreferrer"
							className="text-dark"
						>
							LinkedIn Profile <i className="bi bi-linkedin"></i>
						</a>
					</li>
				</ul>
				<div className="mt-2">
					{/* https://www.credly.com/badges/0b3e4346-3276-40df-be26-c8b0faf8030f/public_url  */}
					<a
						href="https://www.credly.com/badges/0b3e4346-3276-40df-be26-c8b0faf8030f/public_url"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							width="100"
							height="100"
							src={CSABadge}
							alt="AWS Certified Solutions Architect – Associate"
							className="badge-container"
						/>
					</a>
					{/* https://www.credly.com/badges/07ff7eac-837f-4f13-be53-21969b4470f7/public_url */}
					<a
						href="https://www.credly.com/badges/07ff7eac-837f-4f13-be53-21969b4470f7/public_url"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							width="100"
							height="100"
							src={CCPBadge}
							alt="AWS Certified Cloud Practitioner"
							className="badge-container"
						/>
					</a>
					<a
						href="https://www.scrumalliance.org/get-certified/scrum-master-track/certified-scrummaster"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							width="100"
							height="100"
							src={ScrumMasterBadge}
							alt="Scrum Master Badge"
							className="badge-container"
						/>
					</a>
				</div>

				<div className="mt-5">&copy; {year} Joel Breit</div>
			</div>
		</footer>
	);
};

export default Footer;
