import React, { useEffect, useState } from "react";
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Collapse,
	Container,
	Form,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Progress,
	Spinner,
	TabContent,
	TabPane,
} from "reactstrap";
import {
	AVERAGE_ORCHESTRAI_COMPLETION_TIME as EXPECTED_DURATION,
	ORCHESTRAI_LOADING_MESSAGES as LOADING_MESSAGES,
	ORCHESTRAI_TIMEOUT_DURATION as MAX_DURATION,
	VIBE_SUGGESTIONS,
	ORCHESTRAI_SAVE_FILE_VERSION as SAVE_FILE_VERSION,
	PROMPT_SUGGESTIONS,
} from "../assets/Constants";
import OrchestrAIService from "../services/OrchestrAIAPI";
import FeedbackForm from "./FeedbackForm";
import Synthesizer from "./Synthesizer";

const OrchestrAIContent = () => {
	// Login state
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [apiKey, setApiKey] = useState("");
	const [hasPassword, setHasPassword] = useState(true);
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	// Parameters
	const [activeTab, setActiveTab] = useState("1");
	const [vibe, setVibe] = useState("");
	const [fullPrompt, setFullPrompt] = useState("");
	const [input, setInput] = useState("");

	// Loading state
	const [isLoading, setIsLoading] = useState(false);
	const [timeSoFar, setTimeSoFar] = useState(0);
	const [percentComplete, setPercentComplete] = useState(0);
	const [loadingMessage, setLoadingMessage] = useState(LOADING_MESSAGES[0]);
	const [hasGeneratedMusic, setHasGeneratedMusic] = useState(false);
	const [errorMessage, setErrorMessage] = useState();

	// Music generation state
	const [abcNotation, setAbcNotation] = useState("");
	const [description, setDescription] = useState("");

	// File download information
	const [thread, setThread] = useState("");
	const [run, setRun] = useState("");

	// Feedback form state
	const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

	const validateAccess = () => {
		if (hasPassword) {
			if (!name || !password) {
				setErrorMessage("You must enter a name and a password.");
				return;
			}
			if (password !== process.env.REACT_APP_ORCHESTRAI_PASSWORD) {
				setErrorMessage("Incorrect password.");
				return;
			} else {
				setIsAuthenticated(true);
				setIsAuthenticating(false);
			}
		} else {
			if (!apiKey) {
				setErrorMessage("You must enter an API key.");
				return;
			} else {
				setIsAuthenticated(true);
				setIsAuthenticating(false);
			}
		}
	};

	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const handleNotationChange = (e) => {
		setAbcNotation(e.target.value);
	};

	// Calculate % complete
	useEffect(() => {
		let raw = 0;
		if (timeSoFar + 10 >= EXPECTED_DURATION) {
			raw = (timeSoFar / (timeSoFar + 10)) * 100;
		} else {
			raw = (timeSoFar / EXPECTED_DURATION) * 100;
		}
		const rounded = Math.round(raw);
		console.debug("Percent complete:", rounded);
		setPercentComplete(rounded);
	}, [timeSoFar]);

	useEffect(() => {
		if (activeTab === "1") {
			setInput(vibe);
		} else if (activeTab === "3") {
			setInput(fullPrompt);
		}
	}, [activeTab, vibe, fullPrompt]);

	// Add a handler for the API key input change
	const handleApiKeyChange = (e) => {
		setApiKey(e.target.value);
	};

	const suggestVibe = () => {
		const randomIndex = Math.floor(Math.random() * VIBE_SUGGESTIONS.length);
		setVibe(VIBE_SUGGESTIONS[randomIndex]);
	};

	const suggestFullPrompt = () => {
		const randomIndex = Math.floor(
			Math.random() * PROMPT_SUGGESTIONS.length
		);
		setFullPrompt(PROMPT_SUGGESTIONS[randomIndex]);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let orchestrAIService;

		if (!isAuthenticated) {
			validateAccess();
			return;
		}

		if (hasPassword) {
			orchestrAIService = new OrchestrAIService(
				process.env.REACT_APP_OPENAI_API_KEY
			);
		} else {
			orchestrAIService = new OrchestrAIService(apiKey);
		}

		const startTime = Date.now();
		setLoadingMessage(LOADING_MESSAGES[0]);
		setPercentComplete(0);
		setErrorMessage("");
		setIsLoading(true);
		setIsFeedbackOpen(false);

		try {
			let threadId = "";
			let runId = "";

			// If tab 1
			if (activeTab === "1") {
				const content = `Compose a tune that expresses the following vibe: ${vibe}`;
				threadId = await orchestrAIService.createThread(content);
			} else if (activeTab === "3") {
				const content = `Please create a tune for this prompt: ${fullPrompt}`;
				threadId = await orchestrAIService.createThread(content);
			}
			setThread(threadId);
			runId = await orchestrAIService.createRun(threadId);
			setRun(runId);

			let messageIndex = 0;

			// Check run status
			let runStatus;
			let secondsSoFar = 0;
			do {
				runStatus = await orchestrAIService.getRunStatus(
					threadId,
					runId
				);
				console.debug("Run status:", runStatus);

				// Wait for a few seconds before checking again
				const seconds = Math.floor(Math.random() * 8) + 2;
				await new Promise((resolve) =>
					setTimeout(resolve, 1000 * seconds)
				);

				secondsSoFar = Math.floor((Date.now() - startTime) / 1000);
				console.debug("Seconds so far:", secondsSoFar);
				setTimeSoFar(secondsSoFar);
				const progress = secondsSoFar / EXPECTED_DURATION;
				const messagesLength = LOADING_MESSAGES.length;
				console.debug("Progress:", progress);
				messageIndex = Math.floor(progress * messagesLength);
				console.debug("Message index:", messageIndex);
				setLoadingMessage(LOADING_MESSAGES[messageIndex]);

				if (secondsSoFar > MAX_DURATION) {
					setErrorMessage(
						"OrchestrAI took too long to generate music. Please try again."
					);
					setIsLoading(false);
					return;
				}
			} while (runStatus !== "completed");

			const messages = await orchestrAIService.getMessages(threadId);

			console.debug("Messages:", messages);
			const output = messages[0].content[0].text.value;
			console.debug("Output:", output);
			setAbcNotation(output.match(/```([^`]*)```/)[1]);
			setDescription(output.replace(/```([^`]*)```/, ""));
			setHasGeneratedMusic(true);
			console.debug("Generated music:", abcNotation); // It's not updating here either
		} catch (error) {
			console.error("API call failed:", error);
			setErrorMessage(error.message);
			setIsLoading(false);
			return;
		} finally {
			setIsLoading(false);
		}
	};

	const handleDownload = (feedback) => {
		const element = document.createElement("a");
		const file = new Blob(
			[
				`Version: ${SAVE_FILE_VERSION}\n
Name: ${name}\n
Date and Time: ${new Date().toLocaleString()}\n
Input: ${input}\n
Description: ${description}\n
ABC Notation: ${abcNotation}\n
Thread ID: ${thread}\n
Run ID: ${run}\n
Error Message: ${errorMessage}\n,
Feedback: ${feedback}\n`,
			],
			{ type: "text/plain" }
		);
		element.href = URL.createObjectURL(file);
		const fileName = `OrchestrAI_${new Date().toLocaleString()}.txt`;
		element.download = fileName;
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	};

	const toggleFeedback = () => {
		setIsFeedbackOpen(!isFeedbackOpen);
	};

	return (
		<div className="container px-4">
			<Container>
				<h1 className="border-bottom">OrchestrAI</h1>
				<p>
					OrchestrAI is a web application powered by a custom GPT-4
					assistant provided by OpenAI, to generate music notation.
					The user can enter a vibe or a full tune description, and a
					piece of music will be generated in ABC notation. The ABC
					notation can be converted to sheet music using the ABCJS
					library.
				</p>
				{!isAuthenticated && (
					<Button
						onClick={() => setIsAuthenticating(!isAuthenticating)}
						className="primary-button mb-3"
					>
						Try it out{" "}
						<i className={`bi bi-arrow-right-circle`}></i>
					</Button>
				)}
				<Modal isOpen={isAuthenticating}>
					<ModalHeader>
						Validate Access{" "}
						<span className="icon-square flex-shrink-0">
							<i className={`bi bi-shield-check`}></i>
						</span>
					</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup switch>
								<Input
									type="switch"
									id="hasPassword"
									defaultChecked={true}
									onClick={() => {
										setHasPassword(!hasPassword);
									}}
								/>
								<Label check for="hasPassword">
									I have a personal access password
								</Label>
							</FormGroup>
							{hasPassword ? (
								<>
									{/* TODO explain that this is my money */}
									<FormGroup>
										<Label for="name">Name</Label>
										<Input
											type="text"
											id="name"
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											placeholder="Enter name here"
										/>
									</FormGroup>
									<FormGroup>
										<Label for="password">Password</Label>
										<Input
											type="password"
											id="password"
											value={password}
											onChange={(e) =>
												setPassword(e.target.value)
											}
											placeholder="Enter password here"
										/>
									</FormGroup>
								</>
							) : (
								<FormGroup>
									<Label for="apiKey">API Key</Label>
									<Input
										type="password"
										id="apiKey"
										value={apiKey}
										onChange={handleApiKeyChange}
										placeholder="Enter an OpenAI API key here"
									/>
									<FormText>
										Each generation uses OpenAI's{" "}
										<a href="https://platform.openai.com/docs/assistants/overview">
											Assistants API
										</a>
										{/* TODO add more text */}
									</FormText>
								</FormGroup>
							)}
							<FormGroup></FormGroup>
							{errorMessage && (
								<Alert color="danger">{errorMessage}</Alert>
							)}
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => setIsAuthenticating(false)}>
							Cancel
						</Button>

						<Button
							value="Validate Access"
							className="btn btn-primary primary-button"
							onClick={validateAccess}
						>
							Validate Access <i className={`bi bi-key`}></i>
						</Button>
					</ModalFooter>
				</Modal>
				{isAuthenticated && (
					<div>
						<h3>
							Customize Music{" "}
							<span className="icon-square flex-shrink-0">
								<i className={`bi bi-music-note-beamed`}></i>
							</span>
						</h3>

						<Nav tabs>
							<NavItem>
								<NavLink
									className={
										activeTab === "1" ? "active" : ""
									}
									onClick={() => {
										toggleTab("1");
									}}
								>
									Simple{" "}
									<span className="icon-square flex-shrink-0">
										<i className={`bi bi-brush`}></i>
									</span>
								</NavLink>
							</NavItem>
							{/* <NavItem>
								<NavLink
									className={
										activeTab === "2" ? "active" : ""
									}
									onClick={() => {
										toggleTab("2");
									}}
								>
									Intermediate{" "}
									<span className="icon-square flex-shrink-0">
										<i className={`bi bi-tools`}></i>
									</span>
								</NavLink>
							</NavItem> */}
							<NavItem>
								<NavLink
									className={
										activeTab === "3" ? "active" : ""
									}
									onClick={() => {
										toggleTab("3");
									}}
								>
									Advanced{" "}
									<span className="icon-square flex-shrink-0">
										<i
											className={`bi bi-gear-wide-connected`}
										></i>
									</span>
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<Form onSubmit={handleSubmit} className="mt-3">
									<FormGroup>
										<Label
											for="vibe"
											style={{ marginRight: "0.5rem" }}
										>
											Vibe of the composition
										</Label>
										<Button
											className="primary-button"
											size="sm"
											onClick={suggestVibe}
										>
											Suggest one for me{" "}
											<i className={`bi bi-magic`}></i>
										</Button>
										<Input
											type="text"
											id="vibe"
											value={vibe}
											onChange={(e) =>
												setVibe(e.target.value)
											}
											placeholder="Enter a vibe here"
											maxLength={100}
											className="mt-2"
										/>
										<span className="character-counter">
											{vibe.length}/100
										</span>
									</FormGroup>
									<FormFeedback>
										You must enter a vibe and an API key.
									</FormFeedback>
									<FormGroup>
										<Button
											type="submit"
											value="Generate Music"
											className="btn btn-primary primary-button"
											disabled={
												((!name || !hasPassword) &&
													!apiKey) ||
												!vibe ||
												isLoading
											}
										>
											{isLoading && (
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/>{" "}
												</>
											)}
											Generate Music{" "}
											<i
												className={`bi bi-music-note-beamed`}
											></i>
										</Button>
									</FormGroup>
								</Form>
							</TabPane>
							<TabPane tabId="3">
								<Form onSubmit={handleSubmit} className="mt-3">
									<FormGroup>
										<Label
											for="fullPrompt"
											style={{ marginRight: "0.5rem" }}
										>
											Prompt
										</Label>
										<Button
											className="primary-button"
											size="sm"
											onClick={suggestFullPrompt}
										>
											Suggest one for me{" "}
											<i className={`bi bi-magic`}></i>
										</Button>
										<Input
											type="textarea"
											id="fullPrompt"
											value={fullPrompt}
											onChange={(e) =>
												setFullPrompt(e.target.value)
											}
											placeholder="Write a full prompt for what you want the composition to be"
											className="mt-2"
										/>
									</FormGroup>
									<FormFeedback>
										You must enter a vibe and an API key.
									</FormFeedback>
									<FormGroup>
										<Button
											type="submit"
											value="Generate Music"
											className="btn btn-primary primary-button"
											disabled={
												((!name || !hasPassword) &&
													!apiKey) ||
												!fullPrompt ||
												isLoading
											}
										>
											{isLoading && (
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/>{" "}
												</>
											)}
											Generate Music{" "}
											<i
												className={`bi bi-music-note-beamed`}
											></i>
										</Button>
									</FormGroup>
								</Form>
							</TabPane>
							{/* <TabPane tabId="3">Hello, this is tab 3</TabPane> */}
						</TabContent>
						{isLoading && (
							<div>
								<p>{loadingMessage}</p>
								<Progress
									animated
									value={percentComplete}
									className="primary-progress-bar mb-3"
								>
									{percentComplete > 9 && (
										<>{percentComplete}%</>
									)}
								</Progress>
							</div>
						)}
						{hasGeneratedMusic && (
							<>
								<h2>Generated Description</h2>
								{vibe || fullPrompt ? (
									<>
										<h3>Output</h3>
										{description ? (
											<p>{description}</p>
										) : (
											<p>
												*No description was generated
												this time.*
											</p>
										)}
									</>
								) : (
									"Enter a vibe above to generate music."
								)}
								<div style={{ marginTop: "20px" }}>
									<h2>Rendered Sheet Music</h2>
									<Synthesizer
										abcNotation={abcNotation}
										index={0}
									/>
								</div>
								<h2>Generated ABC Notation</h2>
								<Input
									type="textarea"
									value={abcNotation}
									onChange={handleNotationChange}
									placeholder="Enter ABC notation here"
									rows={10}
								/>
								<Button
									onClick={handleDownload}
									className="btn btn-primary primary-button mt-3"
								>
									<div className="icon-square flex-shrink-0">
										{" "}
										Download{" "}
										<i className={`bi bi-download`}></i>
									</div>
								</Button>
								{/* TODO add this back */}
								{/* {!isFeedbackOpen && (
									<Button
										onClick={toggleFeedback}
										className="primary-button mt-3"
									>
										Submit Feedback{" "}
										<i
											className={`bi bi-chat-right-text`}
										></i>
									</Button>
								)} */}
								<hr />
							</>
						)}

						<Collapse isOpen={isFeedbackOpen}>
							<Card>
								<CardHeader>
									<h4>Feedback on Generated Composition</h4>
								</CardHeader>
								<CardBody>
									<FeedbackForm
										toggleFeedback={toggleFeedback}
									/>
								</CardBody>
								<CardFooter>
									<Col className="d-flex justify-content-between">
										<Button onClick={toggleFeedback}>
											Cancel
										</Button>
										<Button className="primary-button">
											Download{" "}
											<i className={`bi bi-download`}></i>
										</Button>
									</Col>
								</CardFooter>
							</Card>
						</Collapse>
					</div>
				)}
			</Container>
		</div>
	);
};

export default OrchestrAIContent;
