import { Link } from "react-router-dom";
import React from "react";

const ResumeContent = () => {
	return (
		<div>
			<h1 className="pb-2 border-bottom">Joel's Resume</h1>
			<div className="b-divider"></div>

			<h3>Professional Experience</h3>
			<ul>
				<li>
					<strong>Software Engineer, Peraton</strong> (2018–Present)
					<ul>
						<li>
							Conducted big data analysis using Kibana and SQL to
							extract insights from large datasets
						</li>
						<li>
							Designed and implemented front-end interfaces using
							HTML, CSS, JavaScript, TypeScript, and Bootstrap
						</li>
						<li>
							Collaborated with cross-functional teams to develop
							software solutions using Java, C++, SQL, XML/JSON,
							and the Java Spring Framework
						</li>
						<li>
							Served as Scrum master for 7-member software team,
							managing daily standups, sprint planning, and
							retrospectives using Jira and Confluence
						</li>
						<li>
							Designed and implemented unit and performance tests
							for software applications to ensure reliability and
							functionality
						</li>
					</ul>
				</li>
			</ul>

			<h3>Education</h3>
			<ul>
				<li>
					<strong>Bachelor’s - Computer Science</strong> 2014-2018
					(graduated) at West Virginia University, Magna Cum
					Laude/Honors Scholar, 3.7 GPA
				</li>
				<li>
					<strong>Master’s - Biostatistics</strong> 2019-2022
					(graduated) at West Virginia University, GPA: 3.9 GPA
				</li>
				<li>
					<strong>Doctorate - Intelligent Systems</strong> 2023+
					(attending) at the University of Pittsburgh
				</li>
			</ul>

			<h3>Certifications</h3>
			<ul>
				<li>
					AWS Certified Cloud Practitioner – Mar. 2019 – May 2026
					(Validation #: NWNQ1DN2D2FQQ5C7)
				</li>
				<li>
					SCRUM ALLIANCE® Certified ScrumMaster® – Aug. 2021 – Aug.
					2025 (Cert ID: 001234992)
				</li>
			</ul>

			<h3>Skills</h3>
			<ul>
				<li>
					Proficient in Python, Java, C++, R, JavaScript, TypeScript,
					and SQL
				</li>
				<li>
					Experienced with AWS, web development, AI/machine learning,
					Agile methodology, databases, and data analysis
				</li>
				<li>
					Skilled in Tableau, Pandas, and other data visualization
					tools
				</li>
			</ul>

			<h3>Publications</h3>
			<ul>
				<li>
					"Response variation as a result of tai chi on resting blood
					pressure in hypertensive adults: An aggregate data
					meta-analysis." Complement Ther Clin Pract 2022;49:101641.
					doi: https://doi.org/10.1016/j.ctcp.2022.101641{" "}
					<a
						href="https://doi.org/10.1016/j.ctcp.2022.101641"
						target="_blank"
						rel="noreferrer"
						className="btn btn-primary btn-sm primary-button"
					>
						Visit <i className="bi bi-box-arrow-up-right"></i>
					</a>
				</li>
			</ul>

			<h3>
				Academic Research (Various Contracts, Grants, and Scholarships)
			</h3>
			<ul>
				<li>
					Ecological Analysis of Access to Exercise Opportunities and
					Physical Inactivity
				</li>
				<li>
					Analysis of Occupation and Industry Suicide and Depression
					Rates (summer research contract)
				</li>
				<li>
					Systematic Review of Test-Retest Reliability With
					Meta-Analysis
				</li>
				<li>
					Regression Analysis of Bankruptcy by Region and Demographics
					(research grant){" "}
					<Link
						to="/bankruptcy_analysis"
						className="btn btn-primary btn-sm primary-button"
					>
						Explore
					</Link>
				</li>
				<li>
					Correlation Analysis of Built Environment and Life
					Expectancy Adjusting for Density
				</li>
				<li>MIDI File Format Parser (honors scholarship)</li>
				<li>
					Machine Learning Model Trained on Public Domain Music
					(honors scholarship){" "}
					<Link
						to="/jami"
						className="btn btn-primary btn-sm primary-button"
					>
						Explore
					</Link>
				</li>
			</ul>

			<h3>Contact Information</h3>
			<ul>
				<li>Email: joel [at] breitest [dot] com</li>
				<li>Phone: 304-545-8854</li>
				<li>
					LinkedIn:{" "}
					<a href="https://www.linkedin.com/in/joel-breit">
						https://www.linkedin.com/in/joel-breit
					</a>
				</li>
			</ul>
		</div>
	);
};

export default ResumeContent;
