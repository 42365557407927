import React from "react";

const AboutContent = () => {
	return (
		<div>
			<h1
				data-aos="zoom-out"
				data-aos-delay="100"
				className="pb-2 border-bottom"
			>
				This Website
			</h1>
			<div className="prose">
				<section data-aos="fade-up">
					<h2>Technologies Used</h2>
					<p>
						I created this website using HTML, CSS, Sass,
						JavaScript, React, Reactstrap, and more. The website is
						hosted using Amazon Web Services (AWS) Amplify and
						Route53, and I am using CodePipeline and GitHub to
						provide continuous code deployment and integration. I am
						also using CodePipeline notifications to notify me via
						Amazon Web Services' Slack chatbot when my code deploys
						after pushing it with git and whether it succeeded or
						failed. All this including the domain name registration
						is just costing me about $1 / month.
					</p>
				</section>

				<section data-aos="fade-up">
					<h2>Why I Made This Website</h2>
					<p>
						I made this website to showcase my skills and experience
						as a software engineer. I also wanted to create a
						website that I could use to experiment with new
						technologies and techniques. I am using this website to
						learn more about web development, AWS, AI, and other
						technologies.
					</p>
				</section>
			</div>
		</div>
	);
};

export default AboutContent;
