import React from "react";

const JAMIContent = () => {
	return (
		<div>
			<h2 className="pb-2 border-bottom">J.A.M.I.</h2>
			<h4>Joel's Artificial Music Intelligence</h4>
			Research conducted by Joel Breit in 2017 under the mentorship of Don
			McLaughlin, Ph.D.
			<h4>Abstract</h4>
			<p>
				This study explores the development and evaluation of Joel's
				Artificial Music Intelligence (JAMI), an artificial intelligence
				system that generates melodies by analyzing patterns of three
				consecutive scale degrees, termed "mumes," in existing major key
				musical compositions. The hypothesis posits that a computer
				program can determine the naturalness of music based on the
				frequency of mumes in previously analyzed compositions. JAMI
				extracts mumes from a database of musical works and utilizes a
				statistical approach to identify and prioritize patterns that
				occur more frequently in the analyzed music, under the
				assumptions that mumes are partially independent from the
				rhythms they are associated with and direction-agnostic. Three
				melody generation algorithms were implemented and tested:
				randomAttempt(), generating a list of random notes;
				diatonicAttempt(), generating a list of random notes within the
				major scale; and smartAttempt(), generating a list of notes
				prioritizing more common mumes found in the database. To
				evaluate the effectiveness of JAMI, 100,000 melodies were
				generated by each algorithm and scored based on their similarity
				to existing music. A sample of musicians ranked the generated
				melodies by their perceived naturalness, demonstrating the
				success of JAMI in producing melodies with a higher degree of
				naturalness when compared to randomly generated compositions.
				This research contributes to the understanding of artificial
				intelligence applications in music generation, with potential
				for further exploration in other musical scales, keys, and
				styles.
			</p>
			<h4>Methods</h4>
			<p>
				The methodology employed in this study consisted of four primary
				stages: data collection, pattern extraction, melody generation,
				and evaluation.
			</p>
			Data Collection: A corpus of musical compositions in major keys was
			curated, with the exclusion of works containing chromaticism to
			maintain the focus on diatonic melodies. This selection ensured a
			manageable scope for the investigation while laying the groundwork
			for future expansion into other keys and scales.
			<ol>
				<li>
					{" "}
					Pattern Extraction:
					<p>
						For each composition in the corpus, triadic mumes were
						identified and extracted using a custom Python script.
						Mumes were represented as scale degree triplets and
						stored in a dictionary with their corresponding
						frequency of occurrence. Subsequently, the data was
						aggregated and analyzed to discern the distribution of
						mume patterns and their prevalence within the corpus.
					</p>
				</li>
				<li>
					{" "}
					Melody Generation:
					<p>
						Three distinct melody generation algorithms were
						implemented using Python:
						<ul>
							{" "}
							a. randomAttempt(): Generated a list of random
							notes, independent of any scale constraints.
						</ul>
						<ul>
							{" "}
							b. diatonicAttempt(): Generated a list of random
							notes confined to the major scale.
						</ul>
						<ul>
							{" "}
							c. smartAttempt(): Generated a list of notes that
							prioritized the selection of more common mumes
							identified in the pattern extraction stage.
						</ul>
						For each algorithm, 100,000 melodies were generated and
						subsequently scored based on their similarity to the
						corpus. The scoring system utilized a multiplying
						principle, wherein the occurrence frequency of each mume
						in a melody was divided by the average occurrence
						frequency across all patterns. The final score was the
						product of these values for all mumes in the melody.
					</p>
				</li>
				<li>
					{" "}
					Evaluation:
					<p>
						{" "}
						Three representative melodies from each
						algorithm—randomAttempt, diatonicAttempt, and
						smartAttempt—were chosen at the 50th percentile of their
						respective scores. A panel of six musicians, blinded to
						the origin of each melody, ranked the melodies based on
						their perceived naturalness. The results were analyzed
						to assess the success of JAMI in generating melodies
						that emulate the naturalness of existing compositions.
					</p>
				</li>
				<li>
					{" "}
					Report Generation using GPT-4:
					<p>
						{" "}
						To synthesize the findings and insights gathered
						throughout the research process, the GPT-4 language
						model, under the supervision of the primary researcher,
						was employed to generate an academic research report
						utilizing the researcher's notes, which documented the
						project's progression and key developments.
					</p>
				</li>
			</ol>
			<h4>Results</h4>
			<p>
				The AI-driven program was assessed through the creation of three
				sets of 100,000 tunes, with each set employing a different
				algorithm for melody generation: random, diatonic, and
				pattern-based. The evaluation of the melodies entailed selecting
				the median-ranked (50th percentile) tune from each set, ensuring
				a representative sample devoid of extreme outliers. These tunes
				were then presented to a cohort of six experienced musicians
				who, unaware of the algorithmic origins of each melody, were
				asked to rank them based on their perceived musical quality. The
				unanimous consensus among the musicians (p = 0.016) was that the
				melody generated by the AI program, which leveraged patterns
				derived from the database of analyzed music, was superior to the
				other two melodies.
			</p>
		</div>
	);
};

export default JAMIContent;
