import React from "react";
import { Col, Row } from "reactstrap";
import Headshot from "../assets/headshot-square.png";
import Page from "../components/Page";

const HomePage = () => {
	return (
		<Page>
			<script
				type="text/javascript"
				async
				src="//cdn.credly.com/assets/utilities/embed.js"
			></script>
			<Row className="d-flex justify-content-left">
				<Col xs="12" md="5" className="d-flex justify-content-center">
					<img
						data-aos="zoom-out"
						src={Headshot}
						alt="Joel Breit"
						className="headshot"
					/>
				</Col>

				{/* align center */}
				<Col
					xs="12"
					md="5"
					className="d-flex flex-column align-items-center name-title-location"
					data-aos="zoom-out"
					data-aos-delay="100"
				>
					{/* Name and title */}
					<h1>Joel Breit</h1>
					<h2>Software Engineer</h2>

					{/* Location */}
					<p>
						<i className="bi bi-geo-alt-fill"></i> Pittsburgh, PA
					</p>
				</Col>
			</Row>
			<hr />
			<div className="prose">
				<p data-aos="fade-up">
					I have been a software engineer for 6 years. In 2018, I
					completed my bachelor’s from WVU in computer science where I
					graduated magna cum laude and as an Honors Scholar through
					the Honors College. In 2022, I also completed my master’s in
					biostatistics from WVU.
				</p>
				<p data-aos="fade-up">
					In my software engineering career, have been working on
					several software projects including:
					<ul>
						<li>
							Acting as scrum master on a software development
							team balancing customer needs and developer
							resources while helping write and maintain a C++
							codebase and utilizing Elasticsearch, Fluentd, and
							Kibana for log analysis.
						</li>
						<li>
							Developing and testing for backend Java code with an
							Oracle Database for networking microservices in a
							production environment relied on by thousands of
							technicians for tens of thousands of daily
							transactions.
						</li>
						<li>
							Developing, designing, and implementing full-stack
							code improving user experience using React,
							TypeScript and Java for forms filled out by tens of
							thousands of businesses annually, and migrating an
							on-prem system to AWS with Lambda, S3, DynamoDB, and
							other cloud services.
						</li>
					</ul>
				</p>
				<p data-aos="fade-up">
					As a student I have utilized Python, R, and JMP for
					regression analyses and meta-analyses including a
					demographic regression analysis published in the 2020 West
					Virginia Rural Health Association Conference and an
					aggregate data meta-analysis published in Complementary
					Therapies in Clinical Practice.
				</p>
				<p data-aos="fade-up">
					In my PhD program in intelligent systems at the University
					of Pittsburgh which I started in 2023, I built a website
					with React JS and AWS to enable music composition from
					natural language via ABC notation and modern LLM APIs, and I
					have been working on a React Native application to enable a
					study using the MediaPipe pose detection library for joint
					range of motion recovery monitoring.
				</p>
				<p data-aos="fade-up">
					Outside of the above, I am a Certified Cloud Practitioner
					and Certified Solutions Architect through Amazon Web
					Services and a Certified Scrum Master through Scrum
					Alliance.
				</p>
				<p data-aos="fade-up">
					On this website, you can learn more about my personal
					projects. You can also find links to my academic research
					and publications. If you are interested in working with me
					or have any questions, please don't hesitate to contact me
					using the methods listed below.
				</p>
				<p data-aos="fade-up">
					Thank you for visiting, and I look forward to connecting
					with you!
				</p>
			</div>
		</Page>
	);
};

export default HomePage;
