import React from "react";
import MusicRendererContent from "../components/MusicRendererContent";
import Page from "../components/Page";

const MusicRendererPage = () => {
	return (
		<Page>
			<MusicRendererContent />
		</Page>
	);
};

export default MusicRendererPage;
