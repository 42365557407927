import axios from "axios";

import { FEW_SHOT_EXAMPLES } from "../assets/Constants";

class OrchestrAIService {
	constructor(apiKey) {
		this.axiosInstance = axios.create({
			baseURL: "https://api.openai.com/v1/",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
				"OpenAI-Beta": "assistants=v1",
			},
		});
	}

	async createThread(content) {
		console.debug(`Creating thread with prompt: ${content}`);
		// Load FEW_SHOT_EXAMPLES
		// FEW_SHOT_EXAMPLES is a list of objects with prompt and response keys
		let msgs = [];
		for (let i = 0; i < FEW_SHOT_EXAMPLES.length; i++) {
			msgs.push({ role: "user", content: FEW_SHOT_EXAMPLES[i].prompt });
			msgs.push({ role: "user", content: FEW_SHOT_EXAMPLES[i].response });
		}
		msgs.push({ role: "user", content: content });
		const response = await this.axiosInstance.post("threads", {
			messages: msgs,
		});
		console.debug(`Thread created with ID: ${response.data.id}`);
		return response.data.id;
	}

	async createRun(threadId) {
		console.debug(`Creating run with thread ID: ${threadId}`);
		const response = await this.axiosInstance.post(
			`threads/${threadId}/runs`,
			{
				assistant_id: process.env.REACT_APP_ORCHESTRAI_ASSISTANT_ID,
			}
		);
		console.debug(`Run created with ID: ${response.data.id}`);
		return response.data.id;
	}

	async getRunStatus(threadId, runId) {
		console.debug(
			`Getting run status for thread ID: ${threadId}, run ID: ${runId}`
		);
		const response = await this.axiosInstance.get(
			`threads/${threadId}/runs/${runId}`
		);
		console.debug(`Run status: ${response.data.status}`);
		return response.data.status;
	}

	async getMessages(threadId) {
		console.debug(`Getting messages for thread ID: ${threadId}`);
		const response = await this.axiosInstance.get(
			`threads/${threadId}/messages`
		);
		console.debug(`Received ${response.data.data.length} messages`);
		return response.data.data;
	}
}

export default OrchestrAIService;
