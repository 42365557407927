import React from "react";
import OrchestrAIContent from "../components/OrchestrAIContent";
import Page from "../components/Page";

const OrchestrAIPage = () => {
	return (
		<Page>
			<OrchestrAIContent />
		</Page>
	);
};

export default OrchestrAIPage;
