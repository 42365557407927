import CostBenefitChart from "../assets/Cost-Benefit_Chart.png";
import HeaderImage from "../assets/Header_Image.jpg";
import React from "react";
import ResultsChart from "../assets/Results_Chart.png";

const EvoSimContent = () => {
	return (
		<div>
			<div className="b-divider"></div>

			<div>
				<h2 className="pb-2 border-bottom">
					Exploring Costly Signaling Theory
				</h2>
				<h4>
					A Computational Simulation of Evolutionary Strategies in
					Population Dynamics
				</h4>
				Research conducted by Joel Breit in 2022-2023.
				<h4>Summary</h4>
				<p>
					This project was conducted to replicate "costly signaling"
					theory - the evolutionary claim that signaling fitness and
					trusting signaling are good evolutionary strategies even
					when signaling actively competes with fitness. The
					development of this simulation required significant effort
					in coding, debugging, and testing. However, I learned a lot
					and found the experience interesting. The model revealed a
					consistent increase in signaling effort, underpinned by an
					increase in trust. The influence of this behavior on overall
					fitness was also evident, with fitness value seen to decline
					relative to the extent of signaling effort. This observation
					mirrors the costly signaling theory's premise, demonstrating
					the inherent trade-off between resources expended on
					signaling and resultant fitness value.
				</p>
				<h4>Background</h4>
				<p>
					I started this project when I was getting particularly
					interested in computer simulations. The first thing that
					comes to mind when I think of computer simulations (at least
					the kind that I can create) is evolutionary model
					simulations. I have this idea from reading Richard Dawkins
					book the selfish gene which several times references how
					computer models have validated some evolutionary theories.
					So, I went to my electronic copy of the selfish gene and
					searched for any references to simulations.
				</p>
				<p>
					The first thing that caught my eye was the handicap
					theory/signaling theory model which actually didn't have a
					simulation created for it but was proven possible with
					mathematical formulas. This theory in particular seemed
					interesting to me because it seems pretty counterintuitive
					and has just enough complexity that it would be nontrivial
					but doable to simulate. Indeed it was not too complex to to
					model Dash my first version of the simulation took about an
					hour to code - but was certainly non-trivial – the final
					version of this simulation took dozens of hours of coding,
					and reading, and notetaking, and formula creating, and
					testing, and debugging etc.
				</p>
				<h4>Costly Signaling Theory</h4>
				<p>
					"The handicap principle is a hypothesis proposed by the
					biologist Amotz Zahavi to explain how evolution may lead to
					"honest" or reliable signalling between animals which have
					an obvious motivation to bluff or deceive each other." -
					Wikipedia
				</p>
				<p>
					The costly signaling theory, also known as the "good genes
					theory" or "hanicap principle", proposes that certain traits
					or behaviors that appear to be a disadvantage in an
					organism's survival or reproduction may actually be
					advantageous because they signal the organism's genetic
					quality to potential mates. The theory suggests that these
					traits or behaviors, which may be costly in terms of energy
					or resources, are only displayed by individuals with
					high-quality genes that can afford the cost. Thus, these
					traits or behaviors serve as signals of genetic quality to
					potential mates, who are more likely to choose a mate with
					these signals.
				</p>
				<img
					src={CostBenefitChart}
					className="img-fluid rounded"
					alt="Cost Benefit Chart"
				/>
				<h5>Some examples:</h5>
				<div className="container">
					<ul className="list-group">
						<li className="list-group-item">
							<strong>
								Brightly colored or elaborate plumage in birds:
							</strong>{" "}
							These traits may make the birds more visible to
							predators, but they also signal to potential mates
							that the individual has high-quality genes that can
							afford the energy and resources required to produce
							such extravagant feathers.
						</li>
						<li className="list-group-item">
							<strong>Long tail feathers in peacocks:</strong>{" "}
							Peacocks' long tail feathers are heavy and make it
							harder for them to fly, but they are used during
							courtship displays to attract females. The females
							choose the males with the most colorful and largest
							tail feathers, which is an indicator of good genes.
						</li>
						<li className="list-group-item">
							<strong>Brightly colored ornaments in fish:</strong>{" "}
							Many fish species have brightly colored ornaments,
							such as fins or scales, that make them more visible
							to predators. But these ornaments also serve as
							signals of genetic quality to potential mates.
						</li>
						<li className="list-group-item">
							<strong>
								Exaggerated vocalizations in certain primates:
							</strong>{" "}
							Some primates make loud, exaggerated vocalizations
							that can be heard from a great distance. These
							vocalizations are costly in terms of energy and can
							alert predators to the primate's presence, but they
							also signal to potential mates that the individual
							has high-quality genes.
						</li>
					</ul>
				</div>
				<img
					src={HeaderImage}
					className="img-fluid rounded"
					alt="Peacock"
				/>
				<h4>Results</h4>
				<img
					src={ResultsChart}
					className="img-fluid rounded"
					alt="Results Chart"
				/>
				<p>
					I ran this simulation with a population of 1000 individuals
					over 1000 generations. In the chart above, you can see that
					signaling effort (the amount of energy males spend
					signaling) immediately increased and increased further as
					trust increased. Trust also increased as honest signaling
					increased. Trust maxed out around 100% and honest signaling
					maxed out around 50%. Value (fitness after adjusting for the
					energy spent signaling) decreased to about 25%. Signaling
					effort and trust were inheritable while quality was random
					between 0 and 1 for each individual. Signal was calculated
					as quality times signaling effort (e.g. quality of 0.5 x 50%
					signaling effort = signal of .25), and value was calculated
					as the remaining quality left after signaling (e.g. quality
					of .8 - 50% signaling effort = value of .4).
				</p>
				<h4>Observations</h4>
				<p>
					This theory relies a lot on a couple nontrivial assumptions.
					First of all the idea that there is a higher cost for less
					capable individuals to signal than there is for more capable
					individuals to signal does a lot of work in this theory. By
					that I mean that theory actually requires that less capable
					individuals pay a higher price for the same amount of
					signaling than more capable individuals, not just that less
					capable individuals have lost despair. It also implies that
					less capable individuals inherently cannot signal as much as
					high quality individuals. In other words if high quality and
					low quality individuals both signaled as much as possible
					high quality individuals would send a stronger signal than
					low quality individuals. If it were the case that any
					individual spending all of it's effort signaling would look
					similar, then this theory would not work; or rather, and
					situations where that is the case signaling and trust are
					not evolutionarily advantageous
				</p>
				<p>
					Adding trust as a parameter makes the situation more
					complicated. Even with the simplest of equations where some
					value should very clearly approach 50% and 25%, adding trust
					creates an adversarial relationship between optimal levels
					for males and females in trust and signaling effort. When
					trust levels are high it is more advantageous for males to
					use more effort the signal which then makes their value to
					the females decrease which causes trust to decrease which
					makes males who spend relatively low effort signaling have
					an advantage which causes the value of males to increase and
					thus trust to increase and the cycle continues.
				</p>
				<h4>Technical Methodology</h4>
				<p>
					This simulation of the costly signaling theory was
					implemented using Python, leveraging its ease-of-use and
					extensive capabilities for numerical computation and data
					visualization.
				</p>
				<p>
					The core components of the simulation are the `Individual`
					and `Population` classes.
				</p>
				<p>
					`Individual` is a representation of a single organism within
					a population. Each Individual has a sex, quality
					(representing genetic fitness), signaling effort (the energy
					or resources expended on signaling), trust (the likelihood
					to use signaling as a measure of value), and an arbitrary
					trait for exploratory purposes. These traits are generated
					randomly for the initial population, but are inherited from
					parents (with some noise) in subsequent generations. Two
					important derived traits are `signal` and `value`. Signal is
					calculated as the product of quality and signaling effort,
					representing how attractive the individual appears to
					potential mates. Value is the remaining quality after
					signaling effort, effectively the "true" fitness of the
					individual.
				</p>
				<p>
					The `Population` class manages a collection of Individuals.
					A Population is initialized with a certain number of
					randomly generated Individuals. In each generation, every
					female in the population has the opportunity to mate with a
					male and produces an offspring. The selection of the male is
					influenced by the female's trust: if her trust is high, she
					is more likely to pick a male based on his signal;
					otherwise, she picks a male randomly. After mating, if the
					fitness of the father is greater than a random number
					between 0 and 1, the offspring survives and is added to the
					next generation.
				</p>
				<p>
					This simulation runs in iterative cycles representing
					generations. In each generation, mating and survival events
					occur until a new generation of the same size as the
					original population is produced. This process is repeated
					for a certain number of generations, with data collected at
					each step to track the evolution of traits over time.
				</p>
				<p>
					The simulation was executed with a population of 1000
					individuals over 1000 generations. The results from each
					generation were collected and analyzed to understand the
					evolutionary dynamics of signaling and trust within this
					modelled population.
				</p>
			</div>
		</div>
	);
};

export default EvoSimContent;
