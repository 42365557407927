import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	Badge,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Popover,
	PopoverBody,
	PopoverHeader,
} from "reactstrap";

// Just use these tags: Data Science, GenAI, Web Development, Music, JavaScript, Python, React, AWS

const projects = [
	{
		title: "OrchestrAI Web App",
		description:
			"Web app for generating music using artificial intelligence. Built with React, Bootstrap, and a custom OpenAI GPT-4-turbo model.",
		link: "https://www.orchestrai.site",
		isNew: true,
		isComingSoon: false,
		externalLink: true,
		icon: "bi-music-note-beamed",
		tags: [
			"JavaScript",
			"React",
			"AWS",
			"GenAI",
			"Web Development",
			"Music",
		],
	},
	{
		title: "Gift Exchange Web App",
		description:
			"Web app for my family's gift exchange. Built with React, Bootstrap, and AWS serverless technologies.",
		link: "https://www.giftexchange.breitest.com/about",
		isNew: true,
		isComingSoon: false,
		externalLink: true,
		icon: "bi-gift",
		tags: ["JavaScript", "React", "AWS", "Web Development"],
	},
	{
		title: "JuxCompose Web App",
		description:
			"Music composition tool for comparing two generated tunes side-by-side. Built with React, ABCjs, and Bootstrap.",
		link: "/juxcompose",
		isNew: true,
		isComingSoon: false,
		externalLink: false,
		icon: "bi-music-note-beamed",
		tags: ["JavaScript", "React", "Music", "Web Development", "AWS"],
	},
	{
		title: "ABC Notation Visualizer",
		description:
			"Music renderer for ABC Notation using ABCjs capable of updating in real-time and playing imput on command.",
		link: "/music_renderer",
		isNew: true,
		isComingSoon: false,
		externalLink: false,
		icon: "bi-music-note-beamed",
		tags: ["JavaScript", "React", "Music", "Web Development", "AWS"],
	},
	{
		title: "Music Generator",
		description:
			"Joel's Artificial Music Intelligence (J.A.M.I.) an AI program to analyze musical patterns and generate harmonically pleasing melodies using Python and open-source MIDI files.",
		link: "/jami",
		isNew: false,
		isComingSoon: false,
		externalLink: false,
		icon: "bi-music-note-beamed",
		tags: ["Python", "Music", "GenAI"],
	},
	{
		title: "Evolutionary Simulation",
		description:
			"Program to simulate the evolutionary principle of 'costly signaling.' Written in Python.",
		link: "/evolutionary_simulation",
		isNew: false,
		isComingSoon: false,
		externalLink: false,
		icon: "bi-diagram-3-fill",
		tags: ["Python", "Data Science"],
	},
	{
		title: "Meta-Analysis",
		description:
			"Response variation as a result of tai chi on resting blood pressure in hypertensive adults: An aggregate data meta-analysis. [Journal of Alternative and Complementary Medicine]",
		link: "https://doi.org/10.1016/j.ctcp.2022.101641",
		isNew: false,
		isComingSoon: false,
		externalLink: true,
		icon: "bi-heart-pulse-fill",
		tags: ["Data Science"],
	},
	{
		title: "Bankruptcy Analysis",
		description:
			"Community level correlation analysis between bankruptcy rates and common factors in individual bankruptcies in American communities using Python, NumPy, and R.",
		link: "/bankruptcy_analysis",
		isNew: false,
		isComingSoon: false,
		externalLink: false,
		icon: "bi-bank",
		tags: ["Python", "Data Science"],
	},
	{
		title: "No Assumption Neural Network",
		description:
			"Neural network written from scratch in Python without libraries or calculus.",
		link: "/neural_network",
		isNew: false,
		isComingSoon: true,
		externalLink: false,
		icon: "bi-cpu-fill",
		tags: ["Python", "GenAI"],
	},
	{
		title: "MIDI Parser",
		description: "A custom Python library for parsing MIDI files.",
		link: "/midi_parser",
		isNew: false,
		isComingSoon: true,
		externalLink: false,
		icon: "bi-file-earmark-music-fill",
		tags: ["Python", "Music"],
	},
];

const ProjectsContent = () => {
	const [showFilters, setShowFilters] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);

	const toggleFilters = () => {
		setShowFilters(!showFilters);
	};

	const toggleTag = (tag) => {
		setSelectedTags((prevTags) =>
			prevTags.includes(tag)
				? prevTags.filter((t) => t !== tag)
				: [...prevTags, tag]
		);
	};

	const filteredProjects = projects.filter((project) =>
		selectedTags.length === 0
			? true
			: project.tags.some((tag) => selectedTags.includes(tag))
	);

	const numProjects = filteredProjects.length;

	return (
		<>
			<h1
				data-aos="zoom-out"
				data-aos-delay="100"
				className="pb-2 border-bottom d-flex justify-content-between"
			>
				{/* TODO count projects */}
				Projects
				<Button
					id="Popover1"
					type="button"
					onClick={toggleFilters}
					color="secondary"
					outline
					className="mb-3"
					// style={{
					// 	borderRadius: "50%",
					// 	width: "40px",
					// 	height: "40px",
					// }}
				>
					<i className={"bi-funnel"}></i>
				</Button>
				<Popover
					flip
					toggle={toggleFilters}
					// Allow clicking outside of popover to close
					trigger="legacy"
					isOpen={showFilters}
					// required for legacy trigger
					target="Popover1"
					hideArrow
					placement="bottom"
				>
					<PopoverHeader>Filters ({numProjects})</PopoverHeader>
					<PopoverBody>
						<Form>
							<div className="mb-3">
								{[
									...new Set(
										projects.flatMap(
											(project) => project.tags
										)
									),
								].map((tag, index) => (
									<FormGroup switch>
										<Input
											type="switch"
											checked={selectedTags.includes(tag)}
											onClick={() => toggleTag(tag)}
											className={
												selectedTags.includes(tag) &&
												`me-1 badge-${tag
													.toLowerCase()
													.replace(/\s+/g, "")}`
											}
										/>
										<Label check>{tag}</Label>
									</FormGroup>
								))}
							</div>
						</Form>
					</PopoverBody>
				</Popover>
			</h1>
			<div className="row g-4 py-2 row-cols-1 row-cols-lg-3">
				{filteredProjects.map((project, index) => (
					<div
						key={index}
						className="col d-flex align-items-start"
						data-aos="fade-up"
					>
						<div className="icon-square bg-light text-dark flex-shrink-0 me-3">
							<i className={project.icon}></i>
						</div>
						<div>
							<h2>{project.title}</h2>
							<div>
								{project.tags.map((tag, tagIndex) => (
									<Badge
										key={tagIndex}
										className={`me-1 badge-${tag
											.toLowerCase()
											.replace(/\s+/g, "")}`}
									>
										{tag}
									</Badge>
								))}
							</div>
							<p>
								{project.description}
								<br />
								{project.isNew && (
									<span className="badge rounded-pill bg-success">
										New!
									</span>
								)}
								{project.isComingSoon && (
									<span className="badge rounded-pill bg-warning text-dark">
										Coming Soon
									</span>
								)}
							</p>
							{project.externalLink ? (
								<a
									href={project.link}
									target="_blank"
									rel="noreferrer"
									className="btn btn-primary primary-button"
								>
									Visit{" "}
									<i className="bi bi-box-arrow-up-right"></i>
								</a>
							) : project.isComingSoon ? (
								<Link to={project.path}>
									<button
										color="dark"
										disabled={project.isComingSoon}
										className="btn btn-outline-dark"
									>
										Explore
									</button>
								</Link>
							) : (
								<Link
									to={project.link}
									className="btn btn-primary primary-button"
								>
									Explore
								</Link>
							)}
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default ProjectsContent;
