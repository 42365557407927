import React from "react";
import Page from "../components/Page";
import ProjectsContent from "../components/ProjectsContent";

const ProjectsPage = () => {
	return (
		<Page>
			<ProjectsContent />
		</Page>
	);
};

export default ProjectsPage;
