import React from "react";
import EvoSimContent from "../components/EvoSimContent";
import Page from "../components/Page";

const EvolutionarySimulationPage = () => {
	return (
		<Page>
			<EvoSimContent />
		</Page>
	);
};

export default EvolutionarySimulationPage;
